<template>
  <AuthWrapper>
    <div class="auth-contents">
      <a-form @finish="handleSubmit" :model="formState" layout="vertical" :rules="rules">
        <sdHeading as="h3">Buat Password Baru</sdHeading>
        <p class="forgot-text">
          Silahkan buat password baru.
        </p>
        <a-form-item name="password" label="Password Baru">
          <a-input-password type="password" v-model:value="formState.password" placeholder="Masukkan Password Baru" />
        </a-form-item>
        <a-form-item name="rePassword" label="Ulangi Password Baru">
          <a-input-password type="password" v-model:value="formState.rePassword" placeholder="Ulangi Password Baru" />
        </a-form-item>
        <a-form-item>
          <sdButton class="btn-signin btn-primary" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Harap Menuggu...' : 'Sign In' }}
          </sdButton>
        </a-form-item>
        <!-- <p class="return-text">Return to <router-link to="/auth/login">Sign In</router-link></p> -->
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { computed, reactive } from 'vue';
import { AuthWrapper } from './style';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';

const FirstLogin = {
  name: 'FirstLogin',
  components: { AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const login = computed(() => state.auth.data);

    const handleSubmit = () => {
      if (formState.password.length < 8) {
        notification.error({
          message: 'Password minimal 8 karakter.',
        });
      } else if (!formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/)) {
        notification.error({
          message: 'Password harus terdiri dari huruf besar, huruf kecil, angka, dan simbol.',
        });
      } else if (formState.password != formState.rePassword) {
        notification.error({
          message: 'Password tidak sama.',
        });
      } else {
        const credential = {
          email: login.value.email,
          password: login.value.password,
          newPassword: formState.password,
        };

        dispatch('createPassword', credential);
      }
    };

    const formState = reactive({
      password: '',
      rePassword: '',
    });

    const rules = {
      password: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
      rePassword: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    return {
      handleSubmit,
      formState,
      rules,
      isLoading,
      login,
    };
  },
};

export default FirstLogin;
</script>
